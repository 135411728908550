<template>
  <v-list>
    <v-list-item>
      <DataValue label="ID" :value="loaId" icon="numeric" />
    </v-list-item>
    <v-list-item>
      <DataValue
        label="Type"
        :value="loaType"
        icon="file-document-outline"
        icon-color="teal lighten-2"
      />
    </v-list-item>
    <v-list-item>
      <DataValue
        label="Signed Date"
        :value="$helpers.formatDate(signedDate)"
        icon="calendar"
        icon-color="green lighten-2"
      />
    </v-list-item>
    <v-list-item>
      <DataValue
        label="Signed By"
        :value="signedBy"
        icon-color="accent"
        icon="account-edit"
      />
    </v-list-item>
    <v-list-item>
      <div class="d-flex">
        <v-avatar size="32" color="indigo lighten-2">
          <v-icon  dark small>
            mdi-file-upload-outline
          </v-icon>
        </v-avatar>
        <div>
          <div class="ml-2 grey--text" style="font-size:11px">Uploaded By</div>
          <v-avatar class="ml-2" size="16">
           <img :src="`${userAvatar}`" :alt="`Employee ${userId}`" />
          </v-avatar>
          <span class="ml-2">{{uploadedBy}}</span>
        </div>
      </div>
      <!-- <DataValue
        :value="uploadedBy"
      /> -->
    </v-list-item>
    <v-list-item @click="downloadFile">
      <DataValue
        value="Download"
        icon-color="blue"
        :href="href"
        icon="file-download-outline"
      />
    </v-list-item>
  </v-list>
</template>

<script>
import DataValue from "./data-value"
export default {
  components: { DataValue },
  props: {
    userId: Number,
    loaId: {
      type: Number,
      default: 0
    },
    loaType: {
      type: String,
      default: ""
    },
    signedDate: {
      type: Number,
      default: () => 0
    },
    signedBy: {
      type: String,
      default: ""
    },
    uploadedBy: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: ""
    },
    download: {
      type: Function,
      default: null
    }
  },
  computed: {
    userAvatar() {
      return this.userId ? this.$helpers.profilePicture(this.userId) : null
    },

  },
  methods: {
    downloadFile() {
      this.download(this.loaId)
    }
  }
}
</script>

<style></style>
